import React from "react";

export const Navigation = (props) => {
  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            {" "}
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>
          <a className="navbar-brand page-scroll" href="/">
        <img src="img/annewesha-logo.png" alt="Brand" />
          </a>{" "}
        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right">
            <li>
              <a href="https://drive.google.com/file/d/1lbhRa9m3f0Gan55_pvn_d2i9L-yeUcRn/view?usp=sharing" className="page-scroll">
                Download Latest Edition
              </a>
            </li>
            <li>
              <a href="/advisory-body" className="page-scroll">
              Advisory Body
              </a>
            </li>
            
            <li>
              <a href="/submit" className="page-scroll">
                Submit Articles
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
